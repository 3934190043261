import { Box, Grid, TextField } from "@mui/material";

const FormInput = ({
  label,
  value,
  placeholder,
  onChange,
  validation,
  error,
  helperText,
  ...props
}) => {
  return (
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          backgroundColor: "transparent",
          padding: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <label style={{ marginRight: "8px", color: "#fff", width: "13%" }}>
          {label}:
        </label>
        <TextField
          fullWidth
          value={value}
          onChange={onChange}
          className="form-text"
          placeholder={placeholder}
          sx={{
            padding: "7px 15px",
            fontSize: "14px",
            backgroundColor: "#1d2b2c",
            border: "unset",
            width: "87%",
          }}
          {...props}
          error={error}
          helperText={helperText}
        />
      </Box>
    </Grid>
  );
};

export default FormInput;
