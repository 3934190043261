import {
  SET_USER_DATA,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERR,
  SHOW_DETAIL,
  GET_ALL_USER_REQUEST,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_ERR,
  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_ERR,
  GRIDE_TOGGLE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCESS,
  FORGOT_PASSWORD_ERR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERR,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERR,
  MODEL_TOGGLE,
  CONTANT_ACTIVE_TAB,
  DRAWER_TOGGLE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERR,
  USER_PROFILE_TOGGLE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERR,
  GET_USER_DETAIL_CONTENT_REQUEST,
  GET_USER_DETAIL_CONTENT_SUCCESS,
  GET_USER_DETAIL_CONTENT_ERR,
  GET_SURVERY_USER_LIST_REQUEST,
  GET_SURVERY_USER_LIST_SUCCESS,
  GET_SURVERY_USER_LIST_ERR,
  GET_SURVERY_DETERMINANTS_LIST_REQUEST,
  GET_SURVERY_DETERMINANTS_LIST_SUCCESS,
  GET_SURVERY_DETERMINANTS_LIST_ERR,
  GET_PAYMENT_HISTORY_LIST_REQUEST,
  GET_PAYMENT_HISTORY_LIST_SUCCESS,
  GET_PAYMENT_HISTORY_LIST_ERR,
  SET_SELECTED_SURVEY_DATA,
  GET_ALL_USER_ASSESSMENT_REQUEST,
  GET_ALL_USER_ASSESSMENT_SUCCESS,
  GET_ALL_USER_ASSESSMENT_ERR,
  GET_USER_ASSESSMENT_DETAIL_REQUEST,
  GET_USER__ASSESSMENT_DETAIL_SUCCESS,
  GET_USER_ASSESSMENT_DETAIL_ERR,
  DELETE_USER_ASSESSMENT_REQUEST,
  DELETE_USER_ASSESSMENT_SUCCESS,
  DELETE_USER_ASSESSMENT_ERR,
  GET_QUESTION_LIST_REQUEST,
  GET_QUESTION_LIST_SUCCESS,
  GET_QUESTION_LIST_ERR,
  GET_PROGRAM_LIST_REQUEST,
  GET_PROGRAM_LIST_SUCCESS,
  GET_PROGRAM_LIST_ERR,
  CREATE_PROGRAM_REQUEST,
  CREATE_PROGRAM_SUCCESS,
  CREATE_PROGRAM_ERR,
  UPDATE_PROGRAM_REQUEST,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_ERR,
  GET_PROGRAM_DETAILS_REQUEST,
  GET_PROGRAM_DETAILS_SUCCESS,
  GET_PROGRAM_DETAILS_ERR,
} from "../../constants/ActionTypes";
import { toast } from "react-toastify";
import axios from "axios";
import { api, assesementApi, devapi } from "../../constants/api";

export function setUserData(content) {
  return {
    type: SET_USER_DATA,
    content,
  };
}

export const userProfileModalToggle = (toggle, modelData) => {
  return {
    type: USER_PROFILE_TOGGLE,
    payload: {
      toggle: toggle,
      modelData: modelData,
    },
  };
};

export const showDetail = (toggle) => {
  return {
    type: SHOW_DETAIL,
    payload: toggle,
  };
};

export const contentActiveTab = (name) => {
  return {
    type: CONTANT_ACTIVE_TAB,
    payload: name,
  };
};

export const modelToggle = (toggle, modelName) => {
  return {
    type: MODEL_TOGGLE,
    payload: {
      toggle: toggle,
      modelName: modelName,
    },
  };
};

export const drawerToggle = (data) => {
  return {
    type: DRAWER_TOGGLE,
    payload: data,
  };
};

export const grideListToggle = (data) => {
  return {
    type: GRIDE_TOGGLE,
    payload: data,
  };
};

export function updateUserData(obj) {
  return (dispatch) => {
    dispatch(setUserData(obj));
  };
}

export const userLogin = (name, password, callBackFunction1) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: [],
    });
    return axios
      .post(`${api}/login`, {
        email: name,
        password: password,
        osPlatform: "web",
      })
      .then((res) => {
        if (
          res?.data?.status === "success" &&
          res?.data?.data?.contentUserRole?.OrgRoleID === 2
        ) {
          localStorage.setItem("authToken", res?.data?.data?.token);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("loginUser", JSON.stringify(res?.data?.data));
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
          });
          callBackFunction1();
        } else {
          toast("Something went wrong!");
        }
      })
      .catch((err) => {
        toast(err?.response?.data?.message);
        dispatch({
          type: LOGIN_ERR,
          payload: err,
        });
      });
  };
};

export const sendEmail = (email, userName, closeTostSendEmail, role) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: SEND_EMAIL_REQUEST,
      payload: {},
    });
    return axios
      .post(
        `${api}/invite_user`,
        {
          fullname: userName,
          email: email,
          roleId: role,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch({
          type: SEND_EMAIL_SUCCESS,
          payload: res,
        });
        if (res.status === 201) {
          closeTostSendEmail();
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast("user already exist!");
        }
        dispatch({
          type: SEND_EMAIL_ERR,
          payload: err,
        });
      });
  };
};

export const getAllUser = () => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_ALL_USER_REQUEST,
      payload: [],
    });
    return axios
      .get(`${api}/get_all_users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_USER_ERR,
          payload: err,
        });
      });
  };
};

export const getUserDetails = (id) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_USER_DETAIL_CONTENT_REQUEST,
      payload: [],
    });
    return axios
      .get(`${api}/contents/get_content_by_user/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({
          type: GET_USER_DETAIL_CONTENT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_DETAIL_CONTENT_ERR,
          payload: err,
        });
      });
  };
};

export const getUser = () => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_USER_REQUEST,
      payload: [],
    });
    return axios
      .get(`${api}/get_my_details`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({
          type: GET_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_ERR,
          payload: err,
        });
      });
  };
};

export const UpdatePassword = (oldPassword, password, passwordConfirm) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
      payload: [],
    });
    return axios
      .post(
        `${api}/update_admin_password`,
        {
          oldPassword: oldPassword,
          password: password,
          passwordConfirm: passwordConfirm,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_PASSWORD_ERR,
          payload: err,
        });
      });
  };
};

export const forgotPassword = (email, alertMsg) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
      payload: {},
    });
    return axios
      .put(`${api}/sendOtp`, {
        email: email,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: FORGOT_PASSWORD_SUCESS,
            payload: res.data,
          });
          alertMsg();
        }
      })
      .catch((err) => {
        dispatch({
          type: FORGOT_PASSWORD_ERR,
          payload: err,
        });
      });
  };
};

export const verifyUser = () => {
  return (dispatch) => {
    dispatch({
      type: VERIFY_USER_REQUEST,
      payload: {},
    });
    return axios
      .post(`${api}/verifyUser`, {
        email: "manoharga.wa@gmail.com",
        otp: 708904,
      })
      .then((res) => {
        dispatch({
          type: VERIFY_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: VERIFY_USER_ERR,
          payload: err,
        });
      });
  };
};

export const userDetail = (id) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_USER_DETAIL_REQUEST,
      payload: {},
    });
    return axios
      .get(`${api}/get/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch({
          type: GET_USER_DETAIL_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_DETAIL_ERR,
          payload: err,
        });
      });
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("authToken");
    dispatch({
      type: DELETE_USER_REQUEST,
      payload: [],
    });
    return axios
      .post(
        `${api}/delete/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast("User deleted successfully");
        dispatch({
          type: DELETE_USER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        toast("Something went wrong");
        dispatch({
          type: DELETE_USER_ERR,
          payload: err,
        });
      });
  };
};

export const getSurveyList = () => {
  return (dispatch) => {
    dispatch({
      type: GET_SURVERY_USER_LIST_REQUEST,
      payload: [],
    });
    return axios
      .post(`${assesementApi}/surveyIdList`, {
        userId: localStorage.getItem("userToken"),
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_SURVERY_USER_LIST_SUCCESS,
            payload: res.data.surveyIdList,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_SURVERY_USER_LIST_ERR,
          payload: err,
        });
      });
  };
};

export const getDeterminants = (surveyId) => {
  return (dispatch) => {
    dispatch({
      type: GET_SURVERY_DETERMINANTS_LIST_REQUEST,
      payload: [],
    });
    return axios
      .post(`${assesementApi}/userscore`, {
        userId: localStorage.getItem("userToken"),
        surveyId: surveyId,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_SURVERY_DETERMINANTS_LIST_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_SURVERY_DETERMINANTS_LIST_ERR,
          payload: err,
        });
      });
  };
};

export const updateSuggestion = (userId, suggestionId) => {
  return (dispatch) => {
    return axios
      .post(`${assesementApi}/updateSuggestionDate`, {
        userId: localStorage.getItem("userToken"),
        SuggestionID: suggestionId,
      })
      .then((res) => {})
      .catch((err) => {});
  };
};

export const updateReference = (userId, suggestionId) => {
  return (dispatch) => {
    return axios
      .post(`${assesementApi}/updateReferenceDate`, {
        userId: localStorage.getItem("userToken"),
        SuggestionID: suggestionId,
      })
      .then((res) => {})
      .catch((err) => {});
  };
};

export const getPaymentHistory = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PAYMENT_HISTORY_LIST_REQUEST,
      payload: [],
    });
    return axios
      .get(`${assesementApi}/getUsersOrder`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_PAYMENT_HISTORY_LIST_SUCCESS,
            payload: res.data?.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PAYMENT_HISTORY_LIST_ERR,
          payload: err,
        });
      });
  };
};

export const setSelectedSurveyData = (data) => {
  return {
    type: SET_SELECTED_SURVEY_DATA,
    payload: data,
  };
};

export const getAllAssessmentUserList = (query) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_ALL_USER_ASSESSMENT_REQUEST,
      payload: [],
    });
    return axios
      .get(`${devapi}/users-list-for-admin${query}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_ALL_USER_ASSESSMENT_SUCCESS,
            payload: res.data?.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_USER_ASSESSMENT_ERR,
          payload: err,
        });
      });
  };
};

export const getAssessmentUserDetails = (id) => {
  const token = localStorage.getItem("authToken");
  return (dispatch) => {
    dispatch({
      type: GET_USER_ASSESSMENT_DETAIL_REQUEST,
      payload: [],
    });
    return axios
      .get(`${devapi}/getUserByIDForAdmin/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_USER__ASSESSMENT_DETAIL_SUCCESS,
            payload: res.data?.data,
          });
          if (res.data?.data && res.data?.data?.userToken) {
            localStorage.setItem("userToken", res.data?.data?.userToken);
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_ASSESSMENT_DETAIL_ERR,
          payload: err,
        });
      });
  };
};

export const deleteAssessmentUser = () => {
  return (dispatch) => {
    dispatch({
      type: DELETE_USER_ASSESSMENT_REQUEST,
      payload: {},
    });
    return axios
      .put(
        `${devapi}/deleteUser`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: DELETE_USER_ASSESSMENT_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: DELETE_USER_ASSESSMENT_ERR,
          payload: err,
        });
      });
  };
};

export const getQuestionList = (surveyId) => {
  return (dispatch) => {
    dispatch({
      type: GET_QUESTION_LIST_REQUEST,
      payload: [],
    });
    return axios
      .post(`${assesementApi}/question`, {
        userId: localStorage.getItem("userToken"),
        surveyId: surveyId,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_QUESTION_LIST_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_QUESTION_LIST_ERR,
          payload: err,
        });
      });
  };
};

export const getProgramList = (query) => {
  return (dispatch) => {
    dispatch({
      type: GET_PROGRAM_LIST_REQUEST,
      payload: {},
    });
    return axios
      .get(`${devapi}/get-program-subscription-list${query}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_PROGRAM_LIST_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PROGRAM_LIST_ERR,
          payload: err,
        });
      });
  };
};

export const createProgram = (data) => {
  return (dispatch) => {
    dispatch({
      type: CREATE_PROGRAM_REQUEST,
      payload: [],
    });
    return axios
      .post(`${devapi}/save-programs-subscriptions`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Custom-Header": "CustomValue",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: CREATE_PROGRAM_SUCCESS,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_PROGRAM_ERR,
          payload: err,
        });
      });
  };
};

export const updateProgram = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROGRAM_REQUEST,
      payload: [],
    });
    return axios
      .post(`${devapi}/update-program-subscription`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Custom-Header": "CustomValue",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: UPDATE_PROGRAM_SUCCESS,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_PROGRAM_ERR,
          payload: err,
        });
      });
  };
};

export const getProgramDetails = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_PROGRAM_DETAILS_REQUEST,
      payload: [],
    });
    return axios
      .get(`${devapi}/get-program-subscription/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: GET_PROGRAM_DETAILS_SUCCESS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PROGRAM_DETAILS_ERR,
          payload: err,
        });
      });
  };
};

export const uploadFile = (data) => {
  return (dispatch) => {
    return axios
      .post(`${devapi}/upload`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err;
      });
  };
};
