import { Checkbox, FormControlLabel } from "@mui/material";
import * as React from "react";
import "./question.scss";

export default function QuestionCheckBox(props) {
  const [defaultValues, setDefaultValues] = React.useState([]);

  React.useEffect(() => {
    setDefaultValues(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <div className="padding-top-10 cus-checkbox-parent">
      {props.option.map((obj, index) => {
        return (
          <div style={{ display: "block" }}>
            <FormControlLabel
              control={
                <Checkbox
                  className="yellow-font"
                  checked={
                    defaultValues.filter(
                      (subObj) => parseInt(subObj) === obj.ID
                    ).length > 0
                      ? true
                      : false
                  }
                  onChange={() => console.log(true)}
                  size={"small"}
                />
              }
              label={obj.OptionText}
              className="cus-label font-transform"
            ></FormControlLabel>
          </div>
        );
      })}
    </div>
  );
}
