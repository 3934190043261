import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./question.scss";
import { connect } from "react-redux";
import QuestionList from "./questionList";
import { getQuestionList } from "../../../../redux/action/user";

let datagetFirstTime = true;

const AssessementProgress = forwardRef((props, ref) => {
  const [getDatafirst, setGetDataFirst] = useState(true);
  const [questionList, setQuestionList] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(1);

  useImperativeHandle(ref, () => ({
    handleBackButton: () => props.setActiveAboutInfo("viewData"),
  }));

  useEffect(() => {
    progressChange();
    if (document.getElementById(`questionIndex${questionIndex - 1}`)) {
      if (getDatafirst) {
        document
          .getElementById(`questionIndex${questionIndex - 1}`)
          .scrollIntoView({
            bottom: "80px",
          });
      } else {
        document
          .getElementById(`questionIndex${questionIndex - 1}`)
          .scrollIntoView({
            behavior: "smooth",
            bottom: "80px",
          });
      }
      setGetDataFirst(false);
    }

    if (questionIndex === questionList.length) {
      return;
    }
  }, [questionIndex]);

  const progressChange = () => {
    const tempDetermin = [...props.user.totalDetermine];
    tempDetermin.map((obj) => {
      if (!obj.isFillUp) {
        const countAnswer = questionList.filter(
          (subObj) =>
            obj.name === subObj.Determinant &&
            subObj.isMandatory === "1" &&
            subObj.Response[0] === ""
        ).length;
        if (countAnswer === 0) {
          obj.isFillUp = true;
        }
      }
      return "";
    });

    // if (tempDetermin.length > 0) {
    //   props.updateUserData({
    //     totalDetermine: tempDetermin,
    //   });
    // }
  };

  useEffect(() => {
    if (props.user?.selectedSurveyData) {
      props.getQuestionList(props.user?.selectedSurveyData?.SurveyId);
    }
  }, []);

  useEffect(() => {
    if (props.user?.questionList && props.user?.questionList?.length > 0) {
      setQuestionList(props.user?.questionList);
      //   if (props.user?.questionList.length > 0) {
      //     props.updateUserData({
      //       activeGroup: props.user?.questionList[0]?.determinantname,
      //     });
      //   }

      if (datagetFirstTime) {
        let questionIndex = 1;
        let notAnswerd = true;

        props.user?.questionList.map((obj) => {
          if (notAnswerd) {
            if (obj.Response[0] !== "") {
              questionIndex = obj.DisplayOrder + 1;
              if (props.user.activeGroup !== obj.Determinant) {
                // props.updateUserData({
                //   activeGroup: obj.Determinant,
                // });
              }
            }
            if (obj.Response[0] === "") {
              notAnswerd = false;
              //   if (props.user.activeGroup !== obj.Determinant) {
              //     props.updateUserData({
              //       activeGroup: obj.Determinant,
              //     });
              //   }
            }
          }
          return "";
        });
        setQuestionIndex(
          questionIndex < props.user?.questionList.length
            ? questionIndex
            : props.user?.questionList.length
        );
      }
    }
  }, [props.user.questionList]);

  return (
    <div
      className="progrss-body"
      style={{
        // height: "400px",
        overflow: "auto",
      }}
    >
      <div className="progrss-body__fixed">
        <QuestionList
          questionList={questionList}
          questionIndex={questionIndex}
        />
      </div>
    </div>
  );
});

AssessementProgress.propTypes = {};
AssessementProgress.defaultProps = {};

const actionCreators = {
  getQuestionList,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators,
  null,
  { forwardRef: true }
)(AssessementProgress);
