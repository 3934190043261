import Home from '../assets/images/home.png';
import Contents from '../assets/images/gridContent.png';
import Profile from '../assets/images/profile-circle-disActive.png';
import Determinents from '../assets/images/folder.png';
import Setting from '../assets/images/setting-2.png';
import Help from '../assets/images/message-question.png';
import LogOut from '../assets/images/logout.png';

export const Navigation = {
  overviewMenu: [
    {
      id: 'dashboard',
      name: 'Dashboard',
      link: '/dashboard',
      image: Home,
    },
    {
      id: 'contents',
      name: 'Contents',
      link: '/content',
      image: Contents,
    },
    {
      id: 'UserManagementList',
      name: 'User Management',
      link: '/user_management_list',
      image: Profile,
    },
    {
      id: 'determinants',
      name: 'Determinants',
      link: '/determinants',
      image: Determinents,
    },
    {
      id: 'pgrmandsub',
      name: 'Program and subscriptions',
      link: '/programs',
      image: Determinents,
    },
  ],
  general: [
    {
      id: 'setting',
      name: 'Settings',
      link: '/setting',
      image: Setting,
    },
    {
      id: 'createContent',
      name: 'Create Content',
      link: '/content/create_new_content',
      image: Contents,
    },
    {
      id: 'help',
      name: 'Help',
      link: '/help',
      image: Help,
    },
    {
      id: 'logout',
      name: 'LogOut',
      link: '/logout',
      image: LogOut,
    },
  ]
}
