import React, { useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
// import ImageResize from "quill-image-resize-module-react";
import "react-quill/dist/quill.snow.css";
import "./Program.scss";
import QuillResizeImage from "quill-resize-image";

Quill.register("modules/imageUploader", ImageUploader);
// Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/resizeImage", QuillResizeImage);

const ATTRIBUTES = ["alt", "height", "width", "style"];

const ParchmentEmbed = Quill.import("blots/block/embed");
class ImageWithStyle extends ParchmentEmbed {
  static create(value) {
    let node = super.create(value);
    if (typeof value === "string") {
      node.setAttribute("src", this.sanitize(value));
    }
    return node;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static match(url) {
    return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url);
  }

  static sanitize(url) {
    return url;
    //return sanitize(url, ['http', 'https', 'data']) ? url : '//:0';
  }

  static value(domNode) {
    return domNode.getAttribute("src");
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
ImageWithStyle.blotName = "imagewithstyle";
ImageWithStyle.tagName = "IMG";
Quill.register(ImageWithStyle, true);

var Font = Quill.import("formats/font");
Font.whitelist = [
  "Roboto",
  "Calibri",
  "Poppins",
  "Open_Sans",
  "Montserrat",
  "Oswald",
  "Jaro",
  "Parkinsans",
  "Arial",
  "Georgia",
  "Times_New_Roman",
  "Courier_New",
  "Ubuntu",
  "Raleway",
  "Quicksand",
  "Playfair_Display",
  "Lora",
  "Fira_Sans",
  "Roboto_Mono",
  "Lobster",
  "Permanent_Marker",
];
Quill.register(Font, true);

const RichTextEditor = ({ value, onChange, index }) => {
  const reactQuillRef = useRef(null);

  return (
    <div>
      <ReactQuill
        ref={reactQuillRef}
        value={value}
        theme="snow"
        style={{
          minHeight: "25vh",
        }}
        placeholder="Enter Details..."
        onChange={(content) => {
          onChange(index, content);
        }}
        modules={{
          toolbar: [
            [{ font: Font.whitelist }],
            [({ list: "ordered" }, { list: "bullet" }, { list: "check" })],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ size: ["small", "medium", "large", "huge"] }],
            ["link", "image", "video", "formula"],
            ["blockquote", "code-block"],
            [{ align: [] }],
            ["clean"],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
          resizeImage: {
            displaySize: true,
          },
        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "imagewithstyle",
          "image",
          "video",
          "alt",
          "width",
          "height",
          "style",
          "align",
        ]}
      />
    </div>
  );
};

export default RichTextEditor;
