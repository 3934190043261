import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Box as MUIBox,
  Tooltip,
  Checkbox,
  ListItemText,
} from "@mui/material";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";

const Filter = ({ filterDataArr, handleFilterClick }) => {
  const [selectedFilterLabel, setSelectedFilterLabel] = useState(null);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    setFilterData(filterDataArr.filter((el) => el.value.length > 0));
  }, [filterDataArr]);

  const handleFilterLabel = (val) => {
    const newObj = filterDataArr.find((el) => el.id === val);
    setSelectedFilterLabel(newObj || null);
  };

  const handleAnsSwitch = (val) => {
    if (selectedFilterLabel) {
      setSelectedFilterLabel({ ...selectedFilterLabel, value: val });
    }
  };

  const handleApplyFilter = () => {
    const updatedFilterData = filterDataArr.map((el) => {
      if (selectedFilterLabel?.id === el.id) {
        if (
          el.type === "input" &&
          Array.isArray(el.value) &&
          typeof selectedFilterLabel.value === "string"
        ) {
          el.value = [...el.value, selectedFilterLabel.value];
        } else {
          el.value = selectedFilterLabel.value;
        }
      }
      return el;
    });
    handleFilterClick(updatedFilterData);
    setSelectedFilterLabel(null);
  };

  const handleRefreshFilter = () => {
    setSelectedFilterLabel(null);
  };

  const handleClearFilter = (id) => {
    // If "all" is passed, clear all filters
    if (id === "all") {
      let updatedFilters = filterDataArr.map((el) => ({
        ...el,
        value: Array.isArray(el.value) ? [] : "",
      }));
      handleFilterClick(updatedFilters);
    } else {
      // Clear a specific filter by id
      let updatedFilters = filterDataArr.map((el) => {
        if (el.id === id) el.value = Array.isArray(el.value) ? [] : "";
        return el;
      });
      handleFilterClick(updatedFilters);
    }
  };

  const validatePositiveNumber = (value) => {
    // Allow empty input or only valid positive numbers (integer or decimal)
    if (value === "" || /^[+]?\d*\.?\d+$/.test(value)) {
      return value;
    }
    return selectedFilterLabel?.value;
  };

  const AnswerSwitch = ({ disabled }) => {
    switch (selectedFilterLabel?.type) {
      case "single-select":
        return (
          <FormControl fullWidth>
            <InputLabel>{selectedFilterLabel?.label}</InputLabel>
            <Select
              value={selectedFilterLabel?.value || ""}
              onChange={(e) => handleAnsSwitch(e.target.value)}
              label={selectedFilterLabel?.label}
              disabled={disabled} // Disabled condition
            >
              {selectedFilterLabel?.dropArr?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "multi-select":
        return (
          <FormControl fullWidth>
            <InputLabel>{selectedFilterLabel?.label}</InputLabel>
            <Select
              multiple
              value={selectedFilterLabel?.value || []}
              onChange={(e) => handleAnsSwitch(e.target.value)}
              label={selectedFilterLabel?.label}
              disabled={disabled} // Disabled condition
              renderValue={(selected) => {
                // Optional: This is for showing selected values in the select input field
                return selected.join(", ");
              }}
            >
              {selectedFilterLabel?.dropArr?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    checked={selectedFilterLabel?.value.includes(option.value)}
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "input":
        return (
          <TextField
            fullWidth
            value={selectedFilterLabel?.value || ""}
            onChange={(e) => handleAnsSwitch(e.target.value)}
            label={selectedFilterLabel?.label}
            placeholder="Enter Value"
            disabled={disabled} // Disabled condition
          />
        );
      case "input-number":
        return (
          <TextField
            fullWidth
            value={selectedFilterLabel?.value || ""}
            onChange={(e) =>
              handleAnsSwitch(validatePositiveNumber(e.target.value))
            }
            disabled={disabled} // Disabled condition
            label={selectedFilterLabel?.label}
            placeholder="Enter Positive Number"
            error={
              isNaN(selectedFilterLabel?.value) ||
              selectedFilterLabel?.value <= 0
            }
            helperText={
              isNaN(selectedFilterLabel?.value) ||
              selectedFilterLabel?.value <= 0
                ? "Please enter a positive number."
                : ""
            }
          />
        );
      //   case "date":
      //     return (
      //       <LocalizationProvider dateAdapter={AdapterDateFns}>
      //         <DatePicker
      //           value={selectedFilterLabel?.value || null}
      //           onChange={(newDate) => handleAnsSwitch(newDate)}
      //           renderInput={(params) => <TextField {...params} fullWidth />}
      //         />
      //       </LocalizationProvider>
      //     );
      default:
        return (
          <FormControl fullWidth>
            <InputLabel>{selectedFilterLabel?.label}</InputLabel>
            <Select
              value={selectedFilterLabel?.value || ""}
              onChange={(e) => handleAnsSwitch(e.target.value)}
              disabled={disabled} // Disabled condition
              label={selectedFilterLabel?.label}
            >
              {selectedFilterLabel?.dropArr?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
    }
  };

  return (
    <Box pt={1} pb={0.5}>
      <Box sx={{ ...FilterBoxForm }}>
        <FormControl sx={{ width: "20%", mr: 1 }} className="ColumnBox">
          <InputLabel>Select Column Label</InputLabel>
          <Select
            value={selectedFilterLabel?.id || ""}
            onChange={(e) => handleFilterLabel(e.target.value)}
            label="Select Column Label"
            sx={{ width: "20%", mr: 1, padding: "5px 15px", color: "#fff" }}
          >
            {filterDataArr?.map((el) => {
              const isAlreadySelected = filterData.some(
                (filter) => filter.id === el.id
              );

              if (!isAlreadySelected) {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.label}
                  </MenuItem>
                );
              }

              return null;
            })}
          </Select>
        </FormControl>
        <Box
          sx={{ width: "30%", mr: 1, padding: "5px 15px" }}
          className="AnsBox"
        >
          {AnswerSwitch({ disabled: !selectedFilterLabel?.id })}
        </Box>
        <Box sx={{ width: "5%" }} display="flex" justifyContent="center">
          <Tooltip title={"Save"} arrow>
            <IconButton onClick={handleApplyFilter}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ width: "5%" }} display="flex" justifyContent="center">
          <Tooltip title={"Refresh"} arrow>
            <IconButton onClick={handleRefreshFilter}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography
          onClick={() => handleClearFilter("all")}
          variant="subtitle1"
          sx={{
            cursor: "pointer",
            textDecorationLine: "underline",
            width: "10%",
            textAlign: "center",
          }}
        >
          Clear Filter
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        overflow="auto"
        maxHeight={140}
      >
        {filterData.map((el) => (
          <Box key={el.id} display="flex" sx={{ ...sxChip }}>
            {Array.isArray(el.value) ? (
              <Typography variant="subtitle1">{`${el.label}: ${el.value.join(
                ", "
              )}`}</Typography>
            ) : (
              <Typography variant="subtitle1">{`${el.label}: ${el.value}`}</Typography>
            )}
            <IconButton
              sx={{ p: 0, ml: 0.5 }}
              onClick={() => handleClearFilter(el.id)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const sxChip = {
  backgroundColor: "#FAAA2F",
  px: 1,
  py: 0.25,
  mt: 0.5,
  mb: 1,
  mr: 1,
  borderRadius: 5,
  "& h6": {
    color: "common.white",
    "&.MuiTypography-root": {
      fontSize: "0.9rem !important",
    },
  },
  "& svg": {
    ml: 0.5,
    fontSize: "1rem",
    color: "common.white",
  },
  "& p": {
    fontSize: "0.8rem",
    color: "#fff",
  },
};
const FilterBoxForm = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  ".ColumnBox": {
    width: "20%",
    label: {
      color: "#fff",
      lineHeight: 1,
      fontSize: "0.8rem",
    },
    ".MuiInputBase-root": {
      width: "100%",
      "div[role='combobox']": {
        padding: "5px",
        fontSize: "0.8rem",
      },
      svg: {
        path: {
          fill: "#fff",
        },
      },
    },
  },
  ".AnsBox": {
    width: "20%",
    label: {
      color: "#fff",
      lineHeight: 1,
      fontSize: "0.8rem",
    },
    ".MuiInputBase-root": {
      width: "100%",
      "div[role='combobox']": {
        padding: "10px",
        fontSize: "0.8rem",
        color: "#fff",
      },
      svg: {
        path: {
          fill: "#fff",
        },
      },
      input: {
        padding: "12px",
        color: "#fff",
        fontSize: "0.8rem",
      },
    },
  },
  ".MuiBox-root ": {
    svg: {
      path: {
        fill: "#fff",
      },
    },
  },
  ".MuiTypography-root": {
    color: "#fff",
  },
};
export default Filter;
