export const ConvertUTCtoLocal = (date, time, dateTime) => {
  // Combine the date and time into a single string (UTC time)
  const combinedDatetime = `${date}T${time}Z`; // The "Z" at the end indicates UTC

  // Create a new Date object, which will be in UTC
  const utcDate = new Date(combinedDatetime);

  // Convert to the system's local time zone
  const localDate = utcDate.toLocaleString(); // Full local date and time

  // Local date only (without time)
  const localDateOnly = utcDate.toLocaleDateString(); // Local date only

  // Local time only (without date)
  const localTimeOnly = utcDate.toLocaleTimeString("en-US", {
    timeZoneName: "short", // Short time zone format (e.g., GMT-4)
  }); // Local time only

  // Full local date and time with time zone information
  const localDateWithTimeZone = utcDate.toLocaleString("en-US", {
    timeZoneName: "short", // Short time zone format (e.g., GMT-4)
  });

  return {
    date: localDateOnly,
    time: localTimeOnly,
    dateTime: localDateWithTimeZone,
  };
};

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function decodeHtmlEntities(text) {
  const doc = new DOMParser().parseFromString(text, "text/html");
  return doc.documentElement.textContent || doc.documentElement.innerText;
}
