import React, { useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import Option from "@mui/icons-material/MoreHorizOutlined";
import TableRow from "@mui/material/TableRow";
import Woman from "../../assets/images/woman.png";
import Like from "../../assets/images/likes-2.png";
import Comment from "../../assets/images/under-review.png";
import DisLike from "../../assets/images/dislikes.png";
import "./usermanagetable.scss";
import Avatar from "@mui/material/Avatar";
import { connect } from "react-redux";

import {
  showDetail,
  getAllUser,
  userDetail,
  getUserDetails,
} from "../../redux/action/user";
import BaseTable from "../BaseTable/BaseTable";

const UserManageTable = (props) => {
  const UserDetails = (userid, toggle, orgUserId) => {
    localStorage.setItem("userAssessmentId", userid);
    props.getUserDetails(orgUserId);
    props.userDetail(userid);
    props.showDetail(toggle);
  };

  useEffect(() => {
    localStorage.setItem("userRole", props?.role);
  }, [props?.role]);

  const headers = [
    { label: "Name", align: "center" },
    { label: "Qualification", align: "center" },
    { label: "Experience", align: "center" },
    { label: "Total Contents", align: "center" },
    { label: "Status", align: "right" },
    { label: "Quick Action", align: "center" },
  ];

  const renderRows = () => {
    return props?.allUsers.data?.map((row, index) => {
      if (row?.jp_contentuserroles[0]?.OrgRoleID === props.role) {
        return (
          <TableRow
            key={index}
            onClick={() =>
              UserDetails(
                row?.UserID,
                true,
                row?.jp_contentuserprofiles[0]?.OrgUserID
              )
            }
            className="table-row"
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              "& th": {
                color: "#fff",
                borderBottom: "1px solid #273334",
              },
              "& td": {
                borderBottom: "1px solid #273334",
              },
            }}
          >
            <TableCell
              component="th"
              scope="row"
              align="left"
              className="text-white"
            >
              <div className="d-flex overEffect align-items-center">
                <Avatar
                  className="mx-3"
                  alt="Travis Howard"
                  src={
                    row?.jp_contentuserprofiles[0]?.Photo
                      ? row?.jp_contentuserprofiles[0]?.Photo
                      : Woman
                  }
                />
                <div>
                  <span className="nameText">
                    {row?.jp_contentuser?.Fullname
                      ? row?.jp_contentuser?.Fullname
                      : "Robot"}{" "}
                    <p
                      className={
                        row?.Status === "A" ? "user-active" : "user-inactive"
                      }
                    >
                      <p className="user-active-on"> </p>
                      {row?.Status === "A" ? "Active" : "Inactive"}
                    </p>{" "}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell align="center" className="text-white qualification">
              {row?.jp_contentuserprofiles[0]?.Expertise
                ? row?.jp_contentuserprofiles[0]?.Expertise
                : "Therapist"}
            </TableCell>
            <TableCell align="center" className="expirence">
              {row?.jp_contentuserprofiles[0]?.YearsOfExperience
                ? row?.jp_contentuserprofiles[0]?.YearsOfExperience
                : 0}
            </TableCell>
            <TableCell align="center" className="text-white totalContent">
              Total
            </TableCell>
            <TableCell align="right" className="text-white">
              <div>
                <span className="user-table-like statushText">
                  {" "}
                  <img
                    src={Like}
                    alt="like"
                    width={18}
                    style={{ marginRight: "7px" }}
                  />
                  status
                </span>
                <span className="user-table-like statushText">
                  {" "}
                  <img
                    src={Comment}
                    alt="like"
                    width={18}
                    style={{ marginRight: "7px" }}
                  />
                  status
                </span>
                <span className="user-table-like statushText">
                  {" "}
                  <img
                    src={DisLike}
                    alt="like"
                    width={18}
                    style={{ marginRight: "7px" }}
                  />
                  Status
                </span>
              </div>
            </TableCell>
            <TableCell align="center" className="text-white">
              <Option />
            </TableCell>
          </TableRow>
        );
      }
      return null;
    });
  };

  return (
    <div>
      <BaseTable
        headers={headers}
        renderRows={renderRows}
        fetchData={props.getAllUser}
        isLoading={props?.allUserLoading}
        height={"380px"}
      />
    </div>
  );
};

UserManageTable.propTypes = {};
UserManageTable.defaultProps = {};

const actionCreators = {
  showDetail,
  getAllUser,
  userDetail,
  getUserDetails,
};

export default connect(
  ({ user }) => ({
    allUsers: user.allUser,
    allUserLoading: user.allUserLoading,
  }),
  actionCreators
)(UserManageTable);
