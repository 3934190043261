import React, { useState, useEffect, useRef } from "react";
import "./userManagementList.scss";
import Box from "@mui/material/Box";
import { connect } from "react-redux";

import Hoc from "../../../component/HOC";
import Teacher from "../../../assets/images/teacher.png";
import Teacher2 from "../../../assets/images/teacher2.png";
import UserProfile from "../../../assets/images/user-square.png";
import UserProfile2 from "../../../assets/images/user-square2.png";
import UserManageTable from "../../../component/UserManageTable";
import { sendEmail, modelToggle } from "../../../redux/action/user";
import DialogView from "../../../component/Model/Dialog";
import BreadcrumbsComponents from "../../../component/Breadcrumbs/Breadcrumbs";
import UserListTable from "../../../component/UserManageTable/UserListTable";
import UserDetails from "../../../component/UserDetails";

const UserManagement = (props) => {
  const childRef = useRef();
  const [role, setRole] = useState(3);
  const [teacherToggle, setTeacherToggle] = useState(true);
  const [adminToggle, setAdminToggle] = useState(false);
  const [userToggle, setUserToggle] = useState(false);
  const [totalUsers, setTotalUsers] = useState();

  const teacher = props?.userDetailsList?.data?.filter(
    (data) => data.jp_contentuserroles[0]?.OrgRoleID === 3
  );
  const admin = props?.userDetailsList?.data?.filter(
    (data) => data.jp_contentuserroles[0]?.OrgRoleID === 2
  );

  const teacherAdmin = (status) => {
    setRole(status);
    if (status === 3) {
      setTeacherToggle(true);
      setAdminToggle(false);
      setUserToggle(false);
    } else if (status === 2) {
      setAdminToggle(true);
      setTeacherToggle(false);
      setUserToggle(false);
    } else if (status === 1) {
      setAdminToggle(false);
      setTeacherToggle(false);
      setUserToggle(true);
    }
  };
  const handleClickOpen = () => {
    props.modelToggle(true, "createUser");
  };

  const handleClose = () => {
    props.modelToggle(false, "createUser");
  };

  useEffect(() => {
    props?.userDetailsList?.data?.map((data) => {
      if (role === 2) {
        setTotalUsers(admin.length);
      } else if (role === 3) {
        setTotalUsers(teacher.length);
      }
    });
  }, [role]);

  return (
    <Hoc
      activeMenu="User Management"
      // subTitle={
      //   props?.shoeDetailToggle ? (
      //     <BreadcrumbsComponents />
      //   ) : !props?.allUserLoading ? (
      //     totalUsers === undefined ? (
      //       admin?.length + " Users you've got.."
      //     ) : (
      //       totalUsers + " Users you've got.."
      //     )
      //   ) : (
      //     "..."
      //   )
      // }
      onClick={handleClickOpen}
      role={role}
      ref={childRef}
    >
      <div className="container-fluid p-0 user-main-container pr-40">
        {!props.shoeDetailToggle && (
          <div className="user-container ">
            <div className="user-menu-item">
              <ul>
                <li
                  onClick={() => teacherAdmin(3)}
                  className={
                    teacherToggle ? "active-tabe" : "li.disActive-tabe"
                  }
                >
                  <span>
                    <img
                      src={teacherToggle ? Teacher2 : Teacher}
                      alt="teacher"
                    />
                  </span>
                  <p className="laberText">TEACHER</p>
                </li>
                <li
                  onClick={() => teacherAdmin(2)}
                  className={adminToggle ? "active-tabe" : "li.disActive-tabe"}
                >
                  <span>
                    <img
                      src={adminToggle ? UserProfile : UserProfile2}
                      alt="userprofile"
                    />
                  </span>
                  <p className="laberText">ADMIN</p>
                </li>
                <li
                  onClick={() => teacherAdmin(1)}
                  className={userToggle ? "active-tabe" : "li.disActive-tabe"}
                >
                  <span>
                    <img
                      src={userToggle ? UserProfile : UserProfile2}
                      alt="userprofile"
                    />
                  </span>
                  <p className="laberText">USERS</p>
                </li>
              </ul>
            </div>
            <div className="user-menu-item">
              <ul>
                <li>
                  <p>
                    <span
                      className={true ? "dot-active" : "dot-inactive"}
                    ></span>
                  </p>
                  <p>{userToggle ? "Paid" : "Active User"}</p>
                </li>
                <li>
                  <p>
                    <span
                      className={false ? "dot-active" : "dot-inactive"}
                    ></span>
                  </p>
                  <p>{userToggle ? "Free" : "Inactive"}</p>
                </li>
              </ul>
            </div>
          </div>
        )}
        <DialogView
          open={props?.modelToggleData?.toggle}
          onClose={handleClose}
          role={role}
          buttonLabel={"SEND INVITE"}
        />
      </div>
      <div>
        <Box
          sx={{
            mt: 2,
          }}
          className="user-body"
        >
          {props.shoeDetailToggle ? (
            <UserDetails
              activePage={"UserManagement"}
              data={props?.selectedUserData}
              ref={childRef}
            />
          ) : role === 1 ? (
            <UserListTable role={role} />
          ) : (
            <UserManageTable role={role} />
          )}
        </Box>
      </div>
    </Hoc>
  );
};

UserManagement.propTypes = {};
UserManagement.defaultProps = {};

const actionCreators = {
  sendEmail,
  modelToggle,
};

export default connect(
  ({ user }) => ({
    sendEmailInvitation: user.sendEmailData,
    shoeDetailToggle: user.shoeDetailToggle,
    modelToggleData: user.modelToggle,
    userDetailsList: user.allUser,
    allUserLoading: user.allUserLoading,
    selectedUserData: user.selectedUser,
  }),
  actionCreators
)(UserManagement);
