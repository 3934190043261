import React from "react";
import "./question.scss";
import { connect } from "react-redux";
import QuestionCheckBox from "./questionCheckBox";
import QuestionTextBox from "./questionTextBox";
import { FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

const QuestionList = (props) => {
  let fleg = 1;
  return (
    <div
      id="questionList"
      className="questions-list"
      style={{ pointerEvents: "none", color: "white" }}
    >
      {props.questionList.map((mainObj, index) => {
        return (
          <div key={index}>
            <div id={`questionIndex${index}`}>
              {mainObj.DisplayOrder <= props.questionIndex && (
                <div>
                  <div
                    id={`questionList${index}`}
                    className={`questions-list__question margin-top-20 ${
                      mainObj.DisplayOrder === props.questionIndex
                        ? "questions-list__question__active"
                        : ""
                    }`}
                  >
                    <div className="questions-list__question--main">
                      <div className="questions-list__question--main--count ">
                        <InputLabel className={`question-title white-color`}>
                          {fleg++}.
                        </InputLabel>
                      </div>
                      <div className="questions-list__question--main--question">
                        <InputLabel
                          style={props.style}
                          className={`break-text question-title font-transform white-color`}
                        >
                          {mainObj.QuestionText}
                        </InputLabel>
                        {mainObj.QuestionType === "Single" ? (
                          <div>
                            <RadioGroup
                              id={`radioGroup-${index}`}
                              className="padding-top-10 cus-radio-parent"
                              defaultChecked={mainObj.Response[0]}
                              onChange={() => console.log(true)}
                            >
                              {mainObj.options.map((obj, index) => {
                                return (
                                  <FormControlLabel
                                    value={obj.ID}
                                    className={`white-color question-option font-transform`}
                                    control={
                                      <Radio
                                        sx={{
                                          "&:hover": {
                                            bgcolor: "transparent",
                                          },
                                        }}
                                        disableRipple
                                        color="default"
                                        checkedIcon={<BpCheckedIcon />}
                                        icon={<BpIcon />}
                                        checked={
                                          mainObj.Response[0].toString() ===
                                          obj.ID.toString()
                                        }
                                        {...props}
                                      />
                                    }
                                    label={obj.OptionText}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </div>
                        ) : mainObj.QuestionType === "Multi" ? (
                          <div>
                            <QuestionCheckBox
                              answerIndex={index}
                              option={mainObj.options}
                              questionIndex={mainObj.userQuestionID}
                              defaultValue={mainObj.Response}
                              displayOrder={mainObj.DisplayOrder}
                            />
                          </div>
                        ) : mainObj.QuestionType === "Text" ? (
                          <QuestionTextBox
                            answerIndex={index}
                            option={mainObj.options}
                            displayOrder={mainObj.DisplayOrder}
                            questionIndex={mainObj.userQuestionID}
                            type={"text"}
                            defaultValue={mainObj.Response}
                            placeholder={
                              props.user.language?.questionList
                                ?.textboxPlaceholder
                            }
                            buttonText={
                              props.user.language?.questionList
                                ?.textboxButtonText
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

QuestionList.propTypes = {};
QuestionList.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(QuestionList);

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 15,
  height: 15,
  zIndex: 1,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#faaa2f",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 15,
    height: 15,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#faaa2f",
  },
});
